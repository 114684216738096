import { green, red } from '@ant-design/colors';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  type ILanguageType,
  type IProduct,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import {
  AddNewLineAfterInBodyBold,
  ConvertInBodyBoldIntoHeading,
  ConvertInBodyList,
  ConvertStartingBoldIntoHeading,
  ExpandInlineListItems,
  FirstHeadingMustBeH2,
  FixBoldInlineTextSpaces,
  FixBoldListSpaces,
  FixTableSyntax,
  type IErrorMessage,
  type IFixResponse,
  type ILintStringResponse,
  NoHeadingWithName,
  NoLowerCaseInHeading,
} from '@mahawi/markdown-linter';
import { fixString } from '@mahawi/markdown-linter';
import { lintString } from '@mahawi/markdown-linter';
import { type IConfig, MD } from '@mahawi/markdown-linter';
import { type RootState } from 'admin/react/reducers';
import { type ILanguageState } from 'admin/react/reducers/language/types';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  type FormInstance,
  Input,
  List,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { type ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import Markdown from 'markdown-to-jsx';
import React, {
  type CSSProperties,
  type Key,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router';
import { type Dispatch } from 'redux';
import slugify from 'slugify';

import { getFilterOption, getFilterSort } from '../../fragments/select-filter';
import {
  copyDescriptions,
  descriptionLive,
  nameLive,
  namesDescriptionsSlugsUpdate,
  slugLive,
} from '../../reducers/product/actions';
import { type IProductState } from '../../reducers/product/types';
import { type IProductsState } from '../../reducers/products/types';
import CopyToClipboard from '../copy-to-clipboard';
import GoogleTranslate from '../google-translate';
import GoogleTranslateAutomat, {
  type ITextTranslate,
} from '../google-translate-automat';

const { Panel } = Collapse;

interface IForm {
  name: string;
  description: string;
  slug: string;
}

interface IFormCopy {
  productSourceUUID: string;
}

interface DataType {
  key: string;
  languageTypeCode: string;
  lineNumber: number;
  ruleName: string;
  ruleDescription: string;
  line: string;
  detail: string;
}

interface IDescriptionProduct {
  value: string;
  label: string;
}

const styleMarkdownLivePreview: CSSProperties = {
  minHeight: '50vh',
  border: '.1rem solid #00000026',
  borderRadius: '6px',
};

const styleMarkdownEditor: CSSProperties = {
  minHeight: '50vh',
};

const translateName = (
  languageCode: string,
  form: FormInstance,
  dispatch: Dispatch,
): void => {
  window.addEventListener(
    'focus',
    async (): Promise<void> => {
      const text: string = await navigator.clipboard.readText();
      const tt: string = text.trim();

      dispatch(nameLive(tt, languageCode));
      dispatch(
        slugLive(
          slugify(text.trim(), { lower: true, strict: true }),
          languageCode,
        ),
      );

      form.submit();
    },
    { once: true },
  );
};

const translateDescription = (
  languageCode: string,
  form: FormInstance,
  dispatch: Dispatch,
): void => {
  window.addEventListener(
    'focus',
    async (): Promise<void> => {
      const text: string = await navigator.clipboard.readText();
      const tt: string = text.trim();

      dispatch(descriptionLive(tt, languageCode));

      form.submit();
    },
    { once: true },
  );
};
const keyDownHandler = async (
  event: KeyboardEvent,
  form: FormInstance,
  languageCode: string,
  dispatch: Dispatch,
): Promise<void> => {
  // alt + w ė - insert to name
  // alt + e ę - insert to description
  // alt + d ∂ - submit form

  if (!['ė', 'ę', '∂'].includes(event.key)) {
    return;
  }

  if (event.key === '∂') {
    form.submit();

    return;
  }

  const text: string = await navigator.clipboard.readText();

  if (event.key === 'ė') {
    dispatch(nameLive(text.trim(), languageCode));
    dispatch(
      slugLive(
        slugify(text.trim(), { lower: true, strict: true }),
        languageCode,
      ),
    );
  } else if (event.key === 'ę') {
    dispatch(descriptionLive(text.trim(), languageCode));
  }
};

const onFinish = (
  { name, description, slug }: IForm,
  productUUID: string,
  languageCode: string,
  dispatch: Dispatch,
): void => {
  const names = [
    {
      code: languageCode,
      value: name.trim(),
    },
  ];

  const descriptions = [
    {
      code: languageCode,
      value: description.trim(),
    },
  ];

  const slugs = [
    {
      code: languageCode,
      value: slug.trim(),
    },
  ];

  dispatch(
    namesDescriptionsSlugsUpdate(productUUID, names, descriptions, slugs),
  );
};

const onFinishCopy = (
  { productSourceUUID }: IFormCopy,
  productUUID: string,
  dispatch: Dispatch,
) => {
  dispatch(copyDescriptions(productSourceUUID, productUUID));
};

const mdLinterConfig: IConfig = {
  siblingsOnly: true,
  lineLength: 20000,
  ignored: [MD.MD041, MD.MD047],
  customRules: [
    FixBoldListSpaces,
    AddNewLineAfterInBodyBold,
    ConvertInBodyList,
    FirstHeadingMustBeH2,
    NoHeadingWithName,
    ConvertInBodyBoldIntoHeading,
    ConvertStartingBoldIntoHeading,
    NoLowerCaseInHeading,
    ExpandInlineListItems,
    FixTableSyntax,
    FixBoldInlineTextSpaces,
  ],
  customRulesConfig: {
    noHeadingWithName: {
      forbiddenNames: ['someRandomTextToJustDefineStringTypeOfArray'],
    },
  },
};

const languageTypeCodeFilter = (dataSource: DataType[]): ColumnFilterItem[] =>
  [
    ...new Set(
      dataSource.map(
        ({ languageTypeCode }: DataType): string => languageTypeCode,
      ),
    ),
  ].map((code: string): ColumnFilterItem => {
    const columnFilterItem: ColumnFilterItem = {
      text: code,
      value: code,
    };

    return columnFilterItem;
  });

const columns = (dataSource: DataType[]): ColumnsType<DataType> => [
  {
    title: 'Rule name',
    dataIndex: 'ruleName',
  },
  {
    title: 'Rule description',
    dataIndex: 'ruleDescription',
  },
  {
    title: 'Language type',
    dataIndex: 'languageTypeCode',
    sorter: (a: DataType, b: DataType): number =>
      stringCompare(a.languageTypeCode, b.languageTypeCode),
    filterSearch: true,
    filters: languageTypeCodeFilter(dataSource),
    onFilter: (value: boolean | Key, record: DataType): boolean =>
      record.languageTypeCode === value,
  },
  {
    title: 'Line number',
    dataIndex: 'lineNumber',
  },
  {
    title: 'Line',
    dataIndex: 'line',
  },
  {
    title: 'Detail',
    dataIndex: 'detail',
  },
];

const infoContent: React.ReactElement = (
  <List>
    <List.Item>
      <Typography.Text strong>option + w</Typography.Text> - insert into name
    </List.Item>
    <List.Item>
      <Typography.Text strong>option + e</Typography.Text> - insert into
      description
    </List.Item>
    <List.Item>
      <Typography.Text strong>option + d</Typography.Text> - submit form
    </List.Item>
  </List>
);

function ProductLanguage({
  Product,
  dispatch,
  Products,
  Language,
  setLanguageTypeCodeError,
}: {
  Product: IProductState;
  dispatch: Dispatch;
  Products: IProductsState;
  Language: ILanguageState;
  setLanguageTypeCodeError: (languageTypeCodeError: string[]) => void;
}): React.ReactElement {
  const [searchParams] = useSearchParams();

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [form] = Form.useForm<IForm>();
  const [formCopy] = Form.useForm<IFormCopy>();
  const [productDescription, setProductDescription] = useState<string>('');
  const [productDescriptionEN, setProductDescriptionEN] =
    useState<ITranslation>();
  const [productName, setProductName] = useState<string>('');
  const [productNameEN, setProductNameEN] = useState<ITranslation>();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [wasCopied, setCopied] = useState(false);
  const [invalidLanguageTranslations, setInvalidLanguageTranslations] =
    useState<Set<string>>(new Set());
  const [copyDescriptionProducts, setCopyDescriptionProducts] = useState<
    IDescriptionProduct[]
  >([]);

  useEffect((): void => {
    setCopied(false);
  }, [Language.languageType?.code]);

  useEffect(() => {
    const eventListener = (e: KeyboardEvent): void => {
      if (!Language.languageType) {
        return;
      }

      keyDownHandler(e, form, Language.languageType.code, dispatch);
    };

    document.addEventListener('keydown', eventListener);

    return () => {
      document.removeEventListener('keydown', eventListener);
    };
  }, [dispatch, Language.languageType?.code, form]);

  const productDescriptionsJson: string = JSON.stringify(Product.descriptions);
  const productNamesJson: string = JSON.stringify(Product.names);
  const productSlugsJson: string = JSON.stringify(Product.slugs);

  useEffect((): void => {
    const descriptionENUE: ITranslation | undefined = Product.descriptions.find(
      (p: ITranslation): boolean => p.code === 'EN',
    );
    setProductDescriptionEN(descriptionENUE);
  }, [Product.descriptions, productDescriptionsJson]);

  useEffect((): void => {
    const invalidLanguageTranslationsUE = new Set<string>();

    Language.languages?.forEach((language: ILanguageType): void => {
      const pn: ITranslation | undefined = Product.names.find(
        (n: ITranslation): boolean => n.code === language.code,
      );
      if (!pn || pn.value.trim().length <= 2) {
        invalidLanguageTranslationsUE.add(language.code);
      }

      const ps: ITranslation | undefined = Product.slugs.find(
        (s: ITranslation): boolean => s.code === language.code,
      );
      if (!ps || ps.value.trim().length <= 2) {
        invalidLanguageTranslationsUE.add(language.code);
      }

      const pd: ITranslation | undefined = Product.descriptions.find(
        (d: ITranslation): boolean => d.code === language.code,
      );
      if (!pd || pd.value.trim().length <= 2) {
        invalidLanguageTranslationsUE.add(language.code);
      }
    });

    setInvalidLanguageTranslations(invalidLanguageTranslationsUE);
  }, [
    Language.languages,
    Product.descriptions,
    Product.names,
    Product.slugs,
    productDescriptionsJson,
    productNamesJson,
    productSlugsJson,
  ]);

  useEffect((): void => {
    const dataSourceUE: DataType[] = [];
    const invalidLanguageTranslationsUE = new Set<string>();

    Product.descriptions.forEach((description: ITranslation): void => {
      if (!mdLinterConfig.customRulesConfig) {
        mdLinterConfig.customRulesConfig = {};
      }

      mdLinterConfig.customRulesConfig.noHeadingWithName.forbiddenNames = [];

      if (Product.brand?.name) {
        mdLinterConfig.customRulesConfig.noHeadingWithName.forbiddenNames.push(
          Product.brand.name,
        );
      }

      let fixedContentLoop: string = description.value;

      const fixed: IFixResponse = fixString(fixedContentLoop, mdLinterConfig);
      fixedContentLoop = fixed.content;

      const lintFixed: ILintStringResponse = lintString(
        fixedContentLoop,
        mdLinterConfig,
      );

      [...lintFixed.errors].forEach(
        (error: IErrorMessage, ind: number): void => {
          const key = `${description.code}-${ind}`;

          dataSourceUE.push({
            key,
            languageTypeCode: description.code,
            lineNumber: error.lineNumber,
            ruleName: error.ruleName,
            ruleDescription: error.ruleDescription,
            line: error.line,
            detail: error.detail,
          });

          invalidLanguageTranslationsUE.add(description.code);
        },
      );

      setDataSource(dataSourceUE);

      if (description.code === Language.languageType?.code) {
        const lastFixed: IFixResponse = fixString(
          fixedContentLoop,
          mdLinterConfig,
        );

        setProductDescription(lastFixed.content);
        form.setFieldsValue({
          description: lastFixed.content,
        });
      }
    });

    setInvalidLanguageTranslations(invalidLanguageTranslationsUE);
  }, [
    form,
    Language.languageType,
    Product.brand?.name,
    Product.descriptions,
    productDescriptionsJson,
  ]);

  useEffect((): void => {
    const productNameENUE: ITranslation | undefined = Product.names.find(
      (p: ITranslation): boolean => p.code === 'EN',
    );
    setProductNameEN(productNameENUE);

    const productNameUE: string | null = getTranslation(
      Product.names,
      Language.languageType,
    );

    const productNameLocalized: string = productNameUE || '';
    setProductName(productNameLocalized);

    const productSlugLocalizedUE: string = slugify(productNameLocalized, {
      lower: true,
      strict: true,
    });

    form.setFieldsValue({
      name: productNameLocalized,
      slug: productSlugLocalizedUE,
    });

    if (!Language.languageType) {
      return;
    }

    dispatch(
      slugLive(productSlugLocalizedUE.trim(), Language.languageType.code),
    );
  }, [
    form,
    Language.languageType?.code,
    Product.names,
    productNamesJson,
    dispatch,
  ]);

  const productsJson: string = JSON.stringify(Products.products);

  useEffect((): void => {
    const productsUE: IProduct[] =
      Products.products?.filter(
        ({ uuid }: IProduct): boolean => uuid !== Product.uuid,
      ) || [];

    setProducts(productsUE);
  }, [productsJson, Products.products, Product.uuid]);

  useEffect((): void => {
    const selectOptionsSourceProducts = products.map((product) => {
      const pn: string | null = getTranslation(
        product.names,
        Language.languageType,
      );

      const label = `${pn || '****'} → ${product.uuid}`;

      return {
        value: product.uuid,
        label,
      };
    });

    setCopyDescriptionProducts(selectOptionsSourceProducts);
  }, [products, Language.languageType?.code]);

  useEffect((): void => {
    setLanguageTypeCodeError([...invalidLanguageTranslations]);
  }, [invalidLanguageTranslations, setLanguageTypeCodeError]);

  const gtLinkName: string = `GT name EN → ${Language.languageType?.code}`;
  const gtLinkNameReversed: string = `GT name ${Language.languageType?.code} → EN`;
  const gtLinkDescription: string = `GT description EN → ${Language.languageType?.code}`;
  const gtLinkDescriptionReversed: string = `GT description ${Language.languageType?.code} → EN`;

  const copyButtonText: string = wasCopied
    ? `${Language.languageType?.code} description is in clipboard`
    : `Copy ${Language.languageType?.code} description to clipboard`;

  const productNameTranslated: string = productNameEN?.value || '';
  const productDescriptionTranslated: string =
    productDescriptionEN?.value || '';

  const texts: ITextTranslate[] = [
    {
      text: productNameTranslated,
      callbacks: [
        (text: string, lc: string): void => {
          form.setFieldsValue({
            name: text,
            slug: slugify(text, { lower: true, strict: true }),
          });

          dispatch(nameLive(text, lc));

          dispatch(slugLive(slugify(text, { lower: true, strict: true }), lc));
        },
      ],
    },
    {
      text: productDescriptionTranslated,
      callbacks: [
        (text: string, lc: string): void => {
          form.setFieldValue('description', text);

          dispatch(descriptionLive(text, lc));
        },
      ],
    },
  ];

  return (
    <Collapse
      defaultActiveKey={
        searchParams.get('productLanguage') === 'open'
          ? ['productLanguage']
          : []
      }
    >
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Product language</strong>
            {invalidLanguageTranslations.size === 0 ? (
              <CheckCircleOutlined style={{ color: green.primary }} />
            ) : (
              <>
                <CloseCircleOutlined style={{ color: red.primary }} />
                <small>
                  {[...invalidLanguageTranslations]
                    .map((code: string): string => code)
                    .join(', ')}
                </small>
              </>
            )}
          </Space>
        }
        key="productLanguage"
      >
        <Space size={16}>
          <Popover title="Shortcuts" trigger="hover" content={infoContent}>
            <InfoCircleOutlined />
          </Popover>

          {[...invalidLanguageTranslations].filter(
            (code: string): boolean => code === Language.languageType?.code,
          ).length === 0 ? (
            <CheckCircleOutlined style={{ color: green.primary }} />
          ) : (
            <CloseCircleOutlined style={{ color: red.primary }} />
          )}
        </Space>
        <Form
          form={formCopy}
          onFinish={(values: IFormCopy): void =>
            onFinishCopy(values, Product.uuid, dispatch)
          }
          layout="vertical"
          disabled={Product.inProcess}
        >
          <Row gutter={16}>
            <Col span={6} offset={15}>
              <Form.Item
                name="productSourceUUID"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string',
                    min: 36,
                  },
                ]}
                label="Product source"
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select source product"
                  filterOption={getFilterOption}
                  filterSort={getFilterSort}
                  options={copyDescriptionProducts}
                />
              </Form.Item>
            </Col>

            <Col span={3}>
              <Space size={16} align="start">
                <Button type="primary" htmlType="submit">
                  Copy descriptions
                </Button>
                <Spin size="small" spinning={Product.inProcess} />
              </Space>
            </Col>
          </Row>
        </Form>

        <Form
          form={form}
          onFinish={(values: IForm): void => {
            if (!Language.languageType) {
              return;
            }

            onFinish(
              values,
              Product.uuid,
              Language.languageType.code,
              dispatch,
            );
          }}
          layout="vertical"
        >
          <Row>
            <Col span={2}>
              <Space size={16} align="start">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Spin size="small" spinning={Product.inProcess} />
              </Space>
            </Col>

            <Col span={2}>
              <GoogleTranslateAutomat
                texts={texts}
                submit={form.submit}
                isSaving={Product.inProcess}
              />
            </Col>

            <Col span={4}>
              <GoogleTranslate
                fromLanguageCode="EN"
                toLanguageCode={Language.languageType?.code}
                name={gtLinkName}
                text={productNameTranslated}
                onClick={(): void => {
                  if (!Language.languageType) {
                    return;
                  }

                  translateName(Language.languageType.code, form, dispatch);
                }}
              />
            </Col>

            <Col span={4}>
              <GoogleTranslate
                fromLanguageCode="EN"
                toLanguageCode={Language.languageType?.code}
                name={gtLinkDescription}
                text={productDescriptionTranslated}
                onClick={(): void => {
                  if (!Language.languageType) {
                    return;
                  }

                  translateDescription(
                    Language.languageType.code,
                    form,
                    dispatch,
                  );
                }}
              />
            </Col>

            <Col span={4}>
              <GoogleTranslate
                fromLanguageCode={Language.languageType?.code}
                toLanguageCode="EN"
                name={gtLinkNameReversed}
                text={productName}
                onClick={(): void => translateName('EN', form, dispatch)}
              />
            </Col>

            <Col span={4}>
              <GoogleTranslate
                fromLanguageCode={Language.languageType?.code}
                toLanguageCode="EN"
                name={gtLinkDescriptionReversed}
                text={productDescription}
                onClick={(): void => translateDescription('EN', form, dispatch)}
              />
            </Col>

            <Col span={4}>
              <CopyToClipboard
                text={productDescription}
                title={copyButtonText}
                size="large"
                type="primary"
              >
                {copyButtonText}
              </CopyToClipboard>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={12}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string',
                    min: 2,
                  },
                ]}
                label="Name"
              >
                <Input
                  placeholder="Name"
                  onBlur={(e): void => {
                    if (!Language.languageType) {
                      return;
                    }

                    dispatch(
                      nameLive(
                        e.target.value.trim(),
                        Language.languageType.code,
                      ),
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                name="slug"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string',
                    min: 2,
                  },
                ]}
                label="Slug"
              >
                <Input
                  placeholder="Slug"
                  onBlur={(e): void => {
                    if (!Language.languageType) {
                      return;
                    }

                    dispatch(
                      slugLive(
                        e.target.value.trim(),
                        Language.languageType.code,
                      ),
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12} className="gutter-row">
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string',
                    min: 2,
                  },
                ]}
                label="Description"
              >
                <Input.TextArea
                  style={styleMarkdownEditor}
                  rows={4}
                  placeholder="Description"
                  onBlur={(e) => {
                    if (!Language.languageType) {
                      return;
                    }

                    dispatch(
                      descriptionLive(
                        e.target.value.trim(),
                        Language.languageType.code,
                      ),
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              span={12}
              className="gutter-row"
              style={styleMarkdownLivePreview}
            >
              <Markdown>{productDescription}</Markdown>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="gutter-row">
              <span>
                Description has {productDescription.length} characters.
              </span>
            </Col>

            <Col span={24} className="gutter-row">
              {dataSource.length === 0 ? (
                <span>No errors, all fine</span>
              ) : (
                <Table columns={columns(dataSource)} dataSource={dataSource} />
              )}
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product, Products, Language }: RootState) => ({
  Product,
  Products,
  Language,
});

export default connect(mapStateToProps)(ProductLanguage);
