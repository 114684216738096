import { removeDiacritics } from '@mahawi/eshop-common/dist/src/remove-diacritics';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { type DefaultOptionType } from 'antd/es/select';

export const getFilterOption = (
  inputValue: string,
  option?: DefaultOptionType,
): boolean => {
  const childrenText: string = removeDiacritics(
    String(option ? option.children : '').toLowerCase(),
  );

  const inputValues: string[] = removeDiacritics(inputValue.trim())
    .toLowerCase()
    .split(' ');

  return inputValues.every((v: string): boolean => childrenText.includes(v));
};

export const getFilterSort = ({
  optionA,
  optionB,
}: {
  optionA: DefaultOptionType;
  optionB: DefaultOptionType;
}): number => {
  const childrenAText: string = String(
    optionA ? optionA.children : '',
  ).toLowerCase();

  const childrenBText: string = String(
    optionB ? optionB.children : '',
  ).toLowerCase();

  return stringCompare(childrenAText, childrenBText);
};
