import { ReloadOutlined } from '@ant-design/icons';
import { type ICountry } from '@mahawi/eshop-common';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { Button, Divider, Space, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { countriesLoadAll } from '../reducers/countries/actions';
import { type ICountriesState } from '../reducers/countries/types';

interface DataType extends ICountry {
  key: string;
}

const { Title } = Typography;

function SettingsCountriesContainer({
  Countries,
  dispatch,
}: {
  Countries: ICountriesState;
  dispatch: Dispatch;
}): React.ReactElement {
  const [barElement, setBarElement] = useState<
    React.ReactElement | undefined
  >();
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [dataSource, setDataSource] = useState<object[]>([]);

  useEffect((): void => {
    const barElementUE: React.ReactElement = (
      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Button
          type={!Countries.countries ? 'primary' : 'default'}
          onClick={(): void => {
            dispatch(countriesLoadAll());
          }}
          disabled={Countries.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        <Typography>
          {Countries.updatedAt &&
            `Updated at ${dayjs(Countries.updatedAt).format(
              'YYYY-MM-DD HH:mm:ss',
            )}`}
        </Typography>
      </Space>
    );

    setBarElement(barElementUE);
  }, [Countries.inProcess, Countries.updatedAt, dispatch, Countries.countries]);

  useEffect((): void => {
    const dataSourceUE: DataType[] | undefined = Countries.countries?.map(
      (country: ICountry): DataType => ({
        ...country,
        key: country.isoCode,
      }),
    );

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.name, b.name),
        filterSearch: true,
        filters: [
          ...new Set(dataSourceUE?.map(({ name }: DataType): string => name)),
        ].map(
          (name: string): ColumnFilterItem => ({
            text: name,
            value: name,
          }),
        ),
        onFilter: (value: string, record: DataType): boolean =>
          record.name === value,
        render: (text: string, record: DataType): React.ReactElement => (
          <Link
            to={`/settings/country/${record.isoCode}`}
            label={text}
            openInNewTab
          />
        ),
        defaultSortOrder: 'ascend',
      },
      {
        title: 'ISO code',
        dataIndex: 'isoCode',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.isoCode, b.isoCode),
        filterSearch: true,
        filters: [
          ...new Set(
            dataSourceUE?.map(({ isoCode }: DataType): string => isoCode),
          ),
        ].map(
          (isoCode: string): ColumnFilterItem => ({
            text: isoCode,
            value: isoCode,
          }),
        ),
        onFilter: (value: string, record: DataType): boolean =>
          record.isoCode === value,
      },
      {
        title: 'ISO2 code',
        dataIndex: 'isoCode2',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.isoCode2, b.isoCode2),
        filterSearch: true,
        filters: [
          ...new Set(
            dataSourceUE?.map(({ isoCode2 }: DataType): string => isoCode2),
          ),
        ].map(
          (isoCode2: string): ColumnFilterItem => ({
            text: isoCode2,
            value: isoCode2,
          }),
        ),
        onFilter: (value: string, record: DataType): boolean =>
          record.isoCode2 === value,
      },
      {
        title: 'Active',
        dataIndex: 'isActive',
        sorter: (a: DataType, b: DataType): number => {
          if (a.isActive === b.isActive) {
            return 0;
          }

          return a.isActive ? -1 : 1;
        },
        filterSearch: true,
        filters: [
          { text: 'Active', value: true },
          { text: 'Inactive', value: false },
        ],
        onFilter: (value: boolean, record: DataType): boolean =>
          record.isActive === value,
        render: (isActive: boolean): React.ReactElement =>
          isActive ? (
            <Typography.Text strong type="success">
              Active
            </Typography.Text>
          ) : (
            <Typography.Text strong type="warning">
              Deactivated
            </Typography.Text>
          ),
      },
    ];

    setColumns(columnsUE);

    if (!dataSourceUE) {
      return;
    }

    setDataSource(dataSourceUE);
  }, [Countries.countries, dispatch]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Typography>
        <Title level={2}>Countries</Title>
      </Typography>

      {barElement}

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Countries.inProcess && !Countries.countries}
      />
    </Space>
  );
}

const mapStateToProps = ({ Countries }: RootState) => ({
  Countries,
});

export default connect(mapStateToProps)(SettingsCountriesContainer);
