import { GlobalOutlined, ReloadOutlined } from '@ant-design/icons';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { type IProductDownloadedList } from '@mahawi/eshop-common/dist/src/types';
import { Button, Divider, Space, Tooltip, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { downloaderLoadProducts } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

interface DataType {
  key: string;
  domain: string;
  category: string;
  url: string;
  price: number;
  stock: number;
  productUUID: string | undefined;
  updatedAt: string;
}

const { Text } = Typography;

function DownloaderProductsContainer({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): React.ReactElement {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    dispatch(downloaderLoadProducts(7));
  }, [dispatch]);

  useEffect((): void => {
    if (!Downloader.products) {
      return;
    }

    const dataSourceUE: DataType[] = Downloader.products.map(
      (product: IProductDownloadedList): DataType => ({
        key: product.uuid,
        domain: product.domain,
        category: product.category,
        url: product.url,
        price: product.price,
        stock: product.stock,
        productUUID: product.product?.uuid,
        updatedAt: product.updatedAt,
      }),
    );

    setDataSource(dataSourceUE);

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Domain',
        dataIndex: 'domain',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            stringCompare(a.domain, b.domain),
          multiple: 4,
        },
        filterSearch: true,
        filters: [
          ...new Set(
            dataSourceUE.map(({ domain }: DataType): string => domain),
          ),
        ]
          .map((domain: string) => ({
            text: domain.trim(),
            value: domain.trim(),
          }))
          .sort((a, b) => stringCompare(a.value, b.value)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.domain === value,
        width: '10%',
      },
      {
        title: 'Category',
        dataIndex: 'category',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            stringCompare(a.category, b.category),
          multiple: 2,
        },
        filterSearch: true,
        filters: [
          ...new Set(
            dataSourceUE.map(({ category }: DataType): string => category),
          ),
        ]
          .map((category: string) => ({
            text: category.trim(),
            value: category.trim(),
          }))
          .sort((a, b) => stringCompare(a.value, b.value)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.category === value,
        width: '10%',
      },
      {
        title: 'URL',
        dataIndex: 'url',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.url, b.url),
        render: (text, record: DataType): React.ReactElement => {
          const url = new URL(text);
          return (
            <Link
              to={`/downloader/product/${record.key}`}
              label={url.pathname
                .replaceAll('/', '')
                .replaceAll('-', ' ')
                .trim()}
            />
          );
        },
        width: '40%',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.price - b.price,
          multiple: 100,
        },
        width: '10%',
      },
      {
        title: 'Stock',
        dataIndex: 'stock',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.stock - b.stock,
          multiple: 3,
        },
        filterSearch: true,
        filters: [
          {
            text: 'In stock',
            value: 'inStock',
          },
          {
            text: 'Full stock',
            value: 'fullStock',
          },
          {
            text: 'Low stock',
            value: 'lowStock',
          },
          {
            text: 'Out of stock',
            value: 'outOfStock',
          },
        ],
        onFilter: (value, record: DataType): boolean => {
          if (value === 'inStock') {
            return record.stock > 0;
          }

          if (value === 'fullStock') {
            return record.stock >= 5;
          }

          if (value === 'lowStock') {
            return record.stock > 0 && record.stock < 5;
          }

          if (value === 'outOfStock') {
            return record.stock === 0;
          }

          return false;
        },
        render: (value: number): React.ReactElement => {
          if (value >= 5) {
            return (
              <Text strong type="success">
                {value}
              </Text>
            );
          }
          if (value >= 2) {
            return (
              <Text strong type="warning">
                {value}
              </Text>
            );
          }

          return (
            <Text strong type="danger">
              {value}
            </Text>
          );
        },
        width: '10%',
      },
      {
        title: 'Updated',
        dataIndex: 'updatedAt',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
          multiple: 6,
        },
        filters: [
          {
            text: 'Last 24 hours',
            value: 1,
          },
          {
            text: 'Last 7 days',
            value: 7,
          },
          {
            text: 'Last 30 days',
            value: 30,
          },
          {
            text: 'Last 90 days',
            value: 90,
          },
          {
            text: 'Last 180 days',
            value: 180,
          },
          {
            text: 'Last 365 days',
            value: 365,
          },
        ],
        onFilter: (value: number, record: DataType): boolean => {
          const diff: number = dayjs().diff(dayjs(record.updatedAt), 'day');

          return diff <= value;
        },
        render: (value: string): string =>
          dayjs(value).format('DD.MM.YYYY HH:mm:ss'),
        width: '10%',
      },
      {
        title: 'Actions',
        dataIndex: 'product',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            stringCompare(a.productUUID, b.productUUID),
          multiple: 5,
        },
        render: (_text, record: DataType): React.ReactElement => (
          <Space size={16} align="start">
            <a
              href={record.url}
              target="_blank"
              rel="noreferrer noopener nofollow"
              aria-label="Open product in new tab"
            >
              <GlobalOutlined />
            </a>
            {record.productUUID && (
              <Link to={`/product/${record.productUUID}`} openInNewTab />
            )}
          </Space>
        ),
        width: '10%',
      },
    ];

    setColumns(columnsUE);
  }, [Downloader.products, Downloader.products?.length]);

  const pageHeaderText: string = `Downloader products (${Downloader.products?.length || 0} items)`;

  return (
    <>
      <Typography.Title level={2}>{pageHeaderText}</Typography.Title>

      <Divider />

      <Space size={16} align="start">
        <Tooltip title="Reload all products">
          <Button
            color="primary"
            onClick={(): void => {
              dispatch(downloaderLoadProducts(-1));
            }}
            disabled={Downloader.inProcess}
          >
            Reload all <ReloadOutlined />
          </Button>
        </Tooltip>

        {[1, 7, 30, 90, 180, 365].map(
          (days: number): React.ReactElement => (
            <Tooltip
              key={days}
              title={`Reload products updated at least ${days} days`}
            >
              <Button
                color="primary"
                onClick={(): void => {
                  dispatch(downloaderLoadProducts(days));
                }}
                disabled={Downloader.inProcess}
              >
                {days} days <ReloadOutlined />
              </Button>
            </Tooltip>
          ),
        )}
      </Space>

      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Downloader.inProcess}
      />
    </>
  );
}

const mapStateToProps = ({ Downloader }: RootState) => ({ Downloader });

export default connect(mapStateToProps)(DownloaderProductsContainer);
