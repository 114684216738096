import { ReloadOutlined } from '@ant-design/icons';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { type ITag } from '@mahawi/eshop-common/dist/src/types';
import { Button, Divider, Space, Tag, Typography } from 'antd';
import { type ColumnType } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Table from '../components/table';
import TagAdd from '../components/tags/tag-add';
import { type RootState } from '../reducers';
import { tagsLoadAll } from '../reducers/tags/actions';
import { type ITagsState } from '../reducers/tags/types';

interface DataType {
  key: string;
  name: string;
  color: string;
}

function SettingsTagsContainer({
  dispatch,
  Tags,
}: {
  dispatch: Dispatch;
  Tags: ITagsState;
}): React.ReactElement {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnType<object>[]>([]);

  useEffect((): void => {
    const columnsUE: ColumnType<object>[] = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.name, b.name),
        onFilter: (value: string, record: DataType): boolean =>
          record.name === value,
        width: '100%',
        render: (_text: string, record: DataType): React.ReactElement => (
          <Tag color={record.color} key={record.name} bordered>
            {record.name}
          </Tag>
        ),
      },
    ];

    if (!Tags.tags) {
      setDataSource([]);

      return;
    }

    const dataSourceUE: DataType[] = Tags.tags.map(
      (tag: ITag): DataType => ({
        key: tag.name,
        name: tag.name,
        color: tag.color,
      }),
    );

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Tags.tags, Tags.updatedAt]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Typography>
        <Typography.Title level={2}>Tags</Typography.Title>
      </Typography>

      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Button
          type={!Tags.tags ? 'primary' : 'default'}
          onClick={(): void => {
            dispatch(tagsLoadAll());
          }}
          disabled={Tags.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        <Typography>
          {Tags.updatedAt &&
            `Updated at ${dayjs(Tags.updatedAt).format('YYYY-MM-DD HH:mm:ss')}`}
        </Typography>
      </Space>

      <TagAdd />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Tags.inProcess && !Tags.tags}
      />
    </Space>
  );
}

const mapStateToProps = ({ Tags }: RootState): { Tags: ITagsState } => ({
  Tags,
});

export default connect(mapStateToProps)(SettingsTagsContainer);
