import { GlobalOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  type ICurrencyType,
  type IEvent,
  type IEventContext,
  type ILeadSession,
  type ILeadSessionHeaders,
  type ILeadSessionScreen,
  type ISessionCategory,
  type ISessionProduct,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { type IConfigState } from 'admin/react/reducers/config/types';
import { type ILanguageState } from 'admin/react/reducers/language/types';
import { type IMessengerState } from 'admin/react/reducers/messenger/types';
import {
  Descriptions,
  List,
  Popover,
  Row,
  Skeleton,
  Space,
  Timeline,
  type TimelineItemProps,
  Typography,
} from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ProductTable from '../product/table';

interface ITimelineItem {
  type: string;
  names?: ITranslation[];
  uuid?: string;
  createdAt: Date;
  code?: string;
  context?: IEventContext | null;
}

dayjs.extend(relativeTime);
dayjs.extend(duration);

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const currencyType: ICurrencyType = {
  isoCode: 'EUR',
  name: 'Euro',
  symbol: '€',
  isSymbolBeforePrice: false,
  decimalPrecision: 2,
};

function MessengerDetail({
  Messenger,
  Language,
  Config,
}: {
  Messenger: IMessengerState;
  Language: ILanguageState;
  Config: IConfigState;
}): React.ReactElement {
  const [sessions, setSessions] = useState<React.ReactElement>();

  useEffect((): void => {
    const sessionsUE: React.ReactElement = (
      <List
        className="sessions-list"
        itemLayout="horizontal"
        dataSource={Messenger.sessions}
        renderItem={(session: ILeadSession): React.ReactElement => {
          const headers: ILeadSessionHeaders | undefined | null =
            session?.headers;
          const sessionStart: Dayjs = dayjs(session.createdAt);
          const screen: ILeadSessionScreen | undefined | null = session?.screen;

          const timelineItems: ITimelineItem[] = [];

          session.products.forEach((product: ISessionProduct): void => {
            timelineItems.push({
              type: 'product',
              ...product,
            });
          });

          session.categories.forEach((category: ISessionCategory): void => {
            timelineItems.push({
              type: 'category',
              ...category,
            });
          });

          session.events.forEach((event: IEvent): void => {
            timelineItems.push({
              type: 'event',
              ...event,
            });
          });

          timelineItems.sort(
            (a: ITimelineItem, b: ITimelineItem): number =>
              dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
          );

          const timelineItemsRecords: TimelineItemProps[] = timelineItems.map(
            (timelineItem: ITimelineItem): TimelineItemProps => {
              let el: React.ReactElement | undefined;
              let color: string = 'red';

              if (timelineItem.names) {
                const translation: string | null = getTranslation(
                  timelineItem.names,
                  Language.languageType,
                );

                const href = `${
                  Config.url
                }/${getLowerCaseLanguageCode(Language.languageType)}/${
                  timelineItem.type
                }/${timelineItem.uuid}`;

                el = (
                  <Space size={16} align="start">
                    <a
                      href={`${timelineItem.type}/${timelineItem.uuid}`}
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      {translation || '---'}
                    </a>
                    <a
                      href={href}
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                      aria-label="Open in new tab"
                    >
                      <GlobalOutlined />
                    </a>
                  </Space>
                );

                color = timelineItem.type === 'product' ? 'green' : 'blue';
              }

              if (timelineItem.code) {
                el = (
                  <>
                    <Row>{timelineItem.code}</Row>
                    <Row style={{ width: '100%' }}>
                      {timelineItem.context && (
                        <Typography.Text style={{ width: '100%' }}>
                          <small style={{ width: '100%' }}>
                            <pre style={{ width: '100%' }}>
                              {JSON.stringify(timelineItem.context, null, 2)}
                            </pre>
                          </small>
                        </Typography.Text>
                      )}
                    </Row>
                  </>
                );
                color = 'purple';
              }

              const timelineItemRecord: TimelineItemProps = {
                key: timelineItem.createdAt.toString(),
                label: dayjs(timelineItem.createdAt).format(DATE_FORMAT),
                color: color,
                children: el,
              };

              return timelineItemRecord;
            },
          );

          timelineItemsRecords.push({
            key: 'session-created',
            label: sessionStart.format(DATE_FORMAT),
            color: 'pink',
            children: 'Session start',
          });

          let durationString: string = 'none';

          if (session.tick) {
            const sessionEnd: Dayjs = dayjs(session.tick);
            const sessionDuration: Duration = dayjs.duration(
              sessionEnd.diff(sessionStart),
            );
            if (sessionDuration.asDays() >= 1) {
              durationString = sessionDuration.format('D [days] HH:mm:ss');
            } else {
              durationString = sessionDuration.format('HH:mm:ss');
            }
          }

          return (
            <Descriptions
              title={session.uuid}
              bordered
              size="small"
              column={{ xs: 3, sm: 3, md: 3 }}
            >
              <Descriptions.Item label="Tick">
                <Space size={16} align="start">
                  {dayjs(session.tick || session.createdAt).fromNow()}
                  <Popover
                    trigger="hover"
                    content={
                      <Typography.Text>
                        {dayjs(session.tick).format(DATE_FORMAT)}
                      </Typography.Text>
                    }
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label="Duration">
                <Space size={16} align="start">
                  {durationString}
                  <Popover
                    trigger="hover"
                    content={
                      <Typography.Text>
                        created at{' '}
                        {dayjs(session.createdAt).format(DATE_FORMAT)}
                      </Typography.Text>
                    }
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label="IP">
                {headers &&
                headers['cf-connecting-ip'] &&
                headers['cf-ipcountry'] !== 'undefined'
                  ? `${headers['cf-connecting-ip']} (${headers['cf-ipcountry']})`
                  : '---'}
              </Descriptions.Item>

              <Descriptions.Item span={3} label="UA">
                <small>{session?.ua?.ua}</small>
              </Descriptions.Item>

              <Descriptions.Item span={1} label="Screen">
                {screen ? (
                  <Space direction="vertical">
                    <Typography.Text>{`${screen.width}x${screen.height} (${screen.availWidth}x${screen.availHeight})`}</Typography.Text>

                    <Typography.Text>
                      colorDepth: {screen.colorDepth}
                    </Typography.Text>

                    <Typography.Text>
                      pixelDepth: {screen.pixelDepth}
                    </Typography.Text>
                  </Space>
                ) : (
                  '---'
                )}
              </Descriptions.Item>

              <Descriptions.Item span={2} label="Query">
                {session?.query && Object.keys(session.query).length > 0
                  ? Object.keys(session.query).map(
                      (key: string): React.ReactElement => (
                        <div key={key}>
                          {key}:&nbsp;
                          {session.query && session.query[key]
                            ? String(session.query[key])
                            : '---'}
                        </div>
                      ),
                    )
                  : '---'}
              </Descriptions.Item>

              <Descriptions.Item span={2} label="Path">
                <small>{session?.path || '---'}</small>
              </Descriptions.Item>

              <Descriptions.Item
                span={1}
                label="Referer"
                style={{ maxWidth: '10%' }}
              >
                <small>{headers?.referer || '---'}</small>
              </Descriptions.Item>

              <Descriptions.Item span={3} label="Timeline">
                <Timeline mode="left" items={timelineItemsRecords} />
              </Descriptions.Item>
            </Descriptions>
          );
        }}
      />
    );

    setSessions(sessionsUE);
  }, [
    Messenger.sessions,
    Messenger.updatedAt,
    Language.languageType?.code,
    Config.url,
  ]);

  return (
    <>
      <Typography.Title level={5}>Basket</Typography.Title>

      {!Messenger.lead.uuid && !Messenger.basketProducts && (
        <Typography.Paragraph>---</Typography.Paragraph>
      )}

      {Messenger.lead.uuid && (
        <ProductTable
          products={Messenger.basketProducts}
          currencyType={currencyType}
        />
      )}

      <Typography.Title level={5}>Sessions</Typography.Title>

      {!Messenger.lead.uuid && !Messenger.sessions && (
        <Typography.Paragraph>---</Typography.Paragraph>
      )}

      {Messenger.lead.uuid && !Messenger.sessions && <Skeleton active />}

      {Messenger.sessions?.length === 0 && (
        <Typography.Paragraph>Empty</Typography.Paragraph>
      )}

      {sessions}
    </>
  );
}

const mapStateToProps = ({ Messenger, Language, Config }: RootState) => ({
  Messenger,
  Language,
  Config,
});

export default connect(mapStateToProps)(MessengerDetail);
