import { type IComparisonPortal } from '@mahawi/eshop-common';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { createReducer } from '@reduxjs/toolkit';

import {
  loadComparisonPortalAll,
  loadComparisonPortalAllResponse,
  loadComparisonPortalCategoriesAll,
  loadComparisonPortalCategoriesAllResponse,
  saveComparisonPortalCategory,
  saveComparisonPortalCategoryResponse,
} from './actions';
import { type IComparisonPortalState } from './types';

export const preloadedState: IComparisonPortalState = {
  inProcess: false,
  portals: undefined,
  categories: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(loadComparisonPortalAll, (state) => {
    state.inProcess = true;
    state.portals = undefined;
  });

  builder.addCase(loadComparisonPortalAllResponse, (state, action) => {
    state.inProcess = false;
    state.portals = action.payload.portals.sort(
      (a: IComparisonPortal, b: IComparisonPortal): number =>
        stringCompare(a.name, b.name),
    );
  });

  builder.addCase(loadComparisonPortalCategoriesAll, (state) => {
    state.inProcess = false;
    state.categories = undefined;
  });

  builder.addCase(
    loadComparisonPortalCategoriesAllResponse,
    (state, action) => {
      state.inProcess = false;
      state.categories = action.payload.categories;
    },
  );

  builder.addCase(saveComparisonPortalCategory, (state) => {
    state.inProcess = true;
  });

  builder.addCase(saveComparisonPortalCategoryResponse, (state) => {
    state.inProcess = false;
  });
});
