import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { createReducer } from '@reduxjs/toolkit';

import {
  ordersLoadAllOrderStatusTypeCodesResponse,
  ordersLoadAllReviewStatusTypeCodesResponse,
  ordersLoadByOrderStatusCodes,
  ordersLoadByOrderStatusCodesResponse,
  ordersReset,
} from './actions';
import { type IOrdersState } from './types';

export const preloadedState: IOrdersState = {
  inProcess: false,
  orders: undefined,
  statusTypeCodes: undefined,
  reviewStatusTypeCodes: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(ordersLoadByOrderStatusCodes, (state) => {
    state.inProcess = true;
    state.orders = undefined;
    state.updatedAt = Date.now();
  });

  builder.addCase(ordersLoadByOrderStatusCodesResponse, (state, action) => {
    state.inProcess = false;
    state.orders = action.payload.orders;
    state.updatedAt = Date.now();

    state.orders.forEach((order) => {
      order.tags.sort((a: string, b: string): number => stringCompare(a, b));
    });
  });

  builder.addCase(
    ordersLoadAllOrderStatusTypeCodesResponse,
    (state, action) => {
      state.statusTypeCodes = action.payload.statusTypeCodes;
      state.updatedAt = Date.now();
    },
  );

  builder.addCase(
    ordersLoadAllReviewStatusTypeCodesResponse,
    (state, action) => {
      state.reviewStatusTypeCodes = action.payload.statusTypeCodes;
      state.updatedAt = Date.now();
    },
  );

  builder.addCase(ordersReset, (state) => {
    state.inProcess = false;
    state.orders = undefined;
    state.updatedAt = undefined;
  });
});
