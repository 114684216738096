import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import {
  type IDimensions,
  type IProductFeatureFlag,
  type IReportWarehouseProductToImport,
  type IWarehouse,
} from '@mahawi/eshop-common/dist/src/types';
import { Space, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Link from '../components/link';
import Table from '../components/table';
import WarehouseProductFilter from '../components/warehouse-product/filter';
import { featureFlagTags } from '../fragments/feature-flag-tags';
import { type ProductTableColumn } from '../fragments/products-table-columns';
import { getTableFeatureFlagsColumnFilters } from '../fragments/table-feature-flags-column';
import { getColumnSearch } from '../fragments/table-get-column-search';
import { type RootState } from '../reducers';
import { type IFeatureFlagsState } from '../reducers/feature-flags/types';
import { reportWarehouseProductToImportLoad } from '../reducers/report/actions';
import { type IReportState } from '../reducers/report/types';

const { Title } = Typography;
const { Text } = Typography;

interface DataType extends IReportWarehouseProductToImport {
  key: string;
}

function ReportWarehouseProductToImportContainer({
  Report,
  FeatureFlags,
}: {
  Report: IReportState;
  FeatureFlags: IFeatureFlagsState;
}): React.ReactElement {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    if (Report.warehouseProductToImport === null) {
      setDataSource([]);
      return;
    }

    const dataSourceUE: DataType[] = [];
    const warehousesName = new Set<string>([]);
    const brandsName = new Set<string>([]);

    Report.warehouseProductToImport?.forEach(
      (product: IReportWarehouseProductToImport): void => {
        warehousesName.add(product.warehouse.name);
        brandsName.add(product.brand);

        dataSourceUE.push({
          key: product.uuid,
          ...product,
        });
      },
    );

    setDataSource(dataSourceUE);

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Warehouse',
        dataIndex: 'warehouse',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.warehouse.name, b.warehouse.name),
        filterSearch: true,
        filters: [...warehousesName]
          .map((warehouse: string) => ({
            text: warehouse,
            value: warehouse,
          }))
          .sort((a, b): number => stringCompare(a.text, b.text)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.warehouse.name === value,
        render: (warehouse: IWarehouse): string => warehouse.name,
        width: '10%',
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.brand, b.brand),
        filterSearch: true,
        filters: [...brandsName]
          .map((brand: string) => ({
            text: brand,
            value: brand,
          }))
          .sort((a, b) => stringCompare(a.text, b.text)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.brand === value,
        width: '10%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.name, b.name),
        render: (name: string, record: DataType): React.ReactElement => (
          <Link
            to={`/downloader/warehouse-product/${record.uuid}`}
            label={name}
            openInNewTab
          />
        ),
        width: '40%',
        ...getColumnSearch('name'),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.price - b.price,
          multiple: 100,
        },
        width: '10%',
      },
      {
        title: 'Stock',
        dataIndex: 'count',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.count - b.count,
          multiple: 3,
        },
        filterSearch: true,
        filters: [
          {
            text: 'In stock',
            value: 'inStock',
          },
          {
            text: 'Full stock',
            value: 'fullStock',
          },
          {
            text: 'Low stock',
            value: 'lowStock',
          },
        ],
        onFilter: (value: boolean | Key, record: DataType): boolean => {
          if (value === 'inStock') {
            return record.count > 0;
          }

          if (value === 'fullStock') {
            return record.count >= 5;
          }

          if (value === 'lowStock') {
            return record.count > 0 && record.count < 5;
          }

          return false;
        },
        render: (value: number): React.ReactElement => {
          if (value >= 5) {
            return (
              <Text strong type="success">
                {value}
              </Text>
            );
          }
          if (value >= 2) {
            return (
              <Text strong type="warning">
                {value}
              </Text>
            );
          }

          return (
            <Text strong type="danger">
              {value}
            </Text>
          );
        },
        width: '10%',
      },
      {
        title: 'Feature flags',
        dataIndex: 'featureFlags',
        sorter: (a: ProductTableColumn, b: ProductTableColumn): number =>
          a.featureFlags.length - b.featureFlags.length,
        filterSearch: true,
        filters: getTableFeatureFlagsColumnFilters({
          dataSource: dataSourceUE,
          FeatureFlags,
        }),
        onFilter: (
          value: boolean | Key,
          record: ProductTableColumn,
        ): boolean => {
          if (value === 'withFeatureFlags') {
            return record.featureFlags.length > 0;
          }

          if (value === 'withoutFeatureFlags') {
            return record.featureFlags.length === 0;
          }

          return record.featureFlags.some(
            (productFeatureFlag: IProductFeatureFlag): boolean => {
              return productFeatureFlag.code === value;
            },
          );
        },
        render: (
          productFeatureFlags: IProductFeatureFlag[],
        ): React.ReactElement[] =>
          featureFlagTags({ productFeatureFlags, FeatureFlags }),
        width: '5%',
        defaultFilteredValue: ['withoutFeatureFlags'],
      },
      {
        title: 'MPN',
        dataIndex: 'mpn',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.mpn, b.mpn),
        filterSearch: true,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.mpn === value,
        width: '10%',
      },
      {
        title: 'GTIN',
        dataIndex: 'gtin',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.gtin, b.gtin),
        filterSearch: true,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.gtin === value,
        width: '10%',
      },
      {
        title: 'Weight',
        dataIndex: 'dimensions',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            a.dimensions.weight - b.dimensions.weight,
          multiple: 3,
        },
        render: (dimensions: IDimensions): string => `${dimensions.weight} kg`,
        width: '10%',
      },
    ];

    setColumns(columnsUE);
  }, [
    Report.warehouseProductToImport,
    Report.updatedAt,
    FeatureFlags.featureFlags,
  ]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Typography>
        <Title level={2}>Report warehouse product to import</Title>
      </Typography>

      <WarehouseProductFilter cb={reportWarehouseProductToImportLoad} />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Report.inProcess}
      />
    </Space>
  );
}

const mapStateToProps = ({
  Report,
  FeatureFlags,
}: RootState): { Report: IReportState; FeatureFlags: IFeatureFlagsState } => ({
  Report,
  FeatureFlags,
});

export default connect(mapStateToProps)(
  ReportWarehouseProductToImportContainer,
);
