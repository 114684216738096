import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { type IBrand } from '@mahawi/eshop-common';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import { type ColumnType } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { Key, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Table from '../components/table';
import { type RootState } from '../reducers';
import { brandAdd, brandsLoadAll } from '../reducers/brand/actions';
import { type IBrandState } from '../reducers/brand/types';

dayjs.extend(relativeTime);

interface DataType {
  key: string;
  name: string;
}

interface IForm {
  name: string;
}

function BrandContainer({
  Brand,
  dispatch,
}: {
  Brand: IBrandState;
  dispatch: Dispatch;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnType<object>[]>([]);

  const onFinish = useCallback(({ name }, dispatch: Dispatch): void => {
    dispatch(brandAdd(name.trim()));
  }, []);

  useEffect((): void => {
    if (!Brand.brands) {
      return;
    }

    const dataSourceUE: DataType[] = Brand.brands.map(
      (brand: IBrand): DataType => ({
        key: brand.uuid,
        name: brand.name,
      }),
    );

    const columnsUE: ColumnType<object>[] = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.name, b.name),
        filterSearch: true,
        filters: [
          ...new Set(dataSourceUE.map(({ name }: DataType): string => name)),
        ]
          .map((name: string) => ({
            text: name,
            value: name,
          }))
          .sort((a, b) => stringCompare(a.text, b.text)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.name === value,
        width: '100%',
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Brand.brands, Brand.updatedAt]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Button
          type={!Brand.brands ? 'primary' : 'default'}
          onClick={(): void => {
            dispatch(brandsLoadAll());
          }}
          disabled={Brand.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        <Typography>
          {Brand.updatedAt && `Updated at ${dayjs(Brand.updatedAt).fromNow()}`}
        </Typography>
      </Space>

      <Form
        form={form}
        onFinish={(values: IForm): void => onFinish(values, dispatch)}
      >
        <Row gutter={[0, 24]}>
          <Col span={8} offset={8} className="gutter-row">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  min: 2,
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Create new brand name"
                prefix={<EditOutlined />}
                type="string"
                disabled={Brand.inProcess}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Brand.inProcess}
      />
    </Space>
  );
}

const mapStateToProps = ({ Brand }: RootState): { Brand: IBrandState } => ({
  Brand,
});

export default connect(mapStateToProps)(BrandContainer);
