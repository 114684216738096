import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import { type ITag } from '@mahawi/eshop-common/dist/src/types';
import { createReducer } from '@reduxjs/toolkit';

import {
  tagsAdd,
  tagsAddResponse,
  tagsLoadAll,
  tagsLoadAllResponse,
} from './actions';
import { type ITagsState } from './types';

export const preloadedState: ITagsState = {
  inProcess: false,
  tags: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(tagsAdd, (state) => {
    state.inProcess = true;
  });

  builder.addCase(tagsAddResponse, (state, action) => {
    if (Array.isArray(state.tags) && action.payload.tag) {
      state.tags.unshift(action.payload.tag);
    }

    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(tagsLoadAll, (state) => {
    state.inProcess = true;
    state.tags = undefined;
  });

  builder.addCase(tagsLoadAllResponse, (state, action) => {
    state.inProcess = false;
    state.tags = action.payload.tags.sort((a: ITag, b: ITag): number =>
      stringCompare(a.name, b.name),
    );
    state.updatedAt = Date.now();
  });
});
