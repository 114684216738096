import {
  EditOutlined,
  GlobalOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  type IAdminCategory,
  type IComparisonPortal,
  type IComparisonPortalTypeCategory,
  type IEbayMarketplace,
  type IEbayMarketplaceCategory,
} from '@mahawi/eshop-common/dist/src/types';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Row,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd';
import { type ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import LanguageButtons from '../components/language-buttons';
import Link from '../components/link';
import { type ISortableListItem } from '../components/sortable-list';
import { SortableList } from '../components/sortable-list';
import Table from '../components/table';
import { getColumnSearch } from '../fragments/table-get-column-search';
import { type RootState } from '../reducers';
import { categoriesLoadAll, categoryAdd } from '../reducers/categories/actions';
import { type ICategoriesState } from '../reducers/categories/types';
import { categorySavePosition } from '../reducers/category/actions';
import { type IConfigState } from '../reducers/config/types';
import { type ILanguageState } from '../reducers/language/types';

interface IForm {
  name: string;
}

interface DataType {
  key: string;
  uuid: string;
  name: string;
  childCategoriesUUIDs: string[];
  position: number;
  comparisonPortalTypes: IComparisonPortalTypeCategory[];
  ebay: { marketplaces: IEbayMarketplaceCategory[] };
  parentCategoryUUID: string | undefined;
}

function ItemTemplate({
  record,
}: {
  record: ISortableListItem;
}): React.ReactElement {
  return <List.Item>{record.name}</List.Item>;
}

function CategoriesContainer({
  dispatch,
  Categories,
  Language,
  Config,
}: {
  dispatch: Dispatch;
  Categories: ICategoriesState;
  Language: ILanguageState;
  Config: IConfigState;
}): React.ReactElement {
  const [form] = Form.useForm<IForm>();

  const onFinish = useCallback(
    ({ name }: IForm): void => {
      if (!Language.languageType) {
        return;
      }

      dispatch(categoryAdd(name, Language.languageType.code));
    },
    [dispatch, Language.languageType?.code],
  );

  const [columns, setColumns] = useState<ColumnsType>([]);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [dataSourceSortableList, setDataSourceSortableList] = useState<
    ISortableListItem[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOnlyParentCategories, setShowOnlyParentCategories] =
    useState(true);

  useEffect((): void => {
    if (!Categories.categories) {
      return;
    }

    const dataSourceUE: DataType[] = Categories.categories
      .map((category: IAdminCategory): DataType | null => {
        if (category.parentCategoryUUID && showOnlyParentCategories) {
          return null;
        }

        const nameLocalized: string | null = getTranslation(
          category.names,
          Language.languageType,
        );

        return {
          key: category.uuid,
          uuid: category.uuid,
          name: nameLocalized || '---',
          childCategoriesUUIDs: category.childCategoriesUUIDs,
          position: category.position,
          comparisonPortalTypes: category.comparisonPortalTypes,
          ebay: category.ebay,
          parentCategoryUUID: category.parentCategoryUUID,
        };
      })
      .filter(notEmpty);

    const showParentOrChildCategoriesColumn = () => {
      if (showOnlyParentCategories) {
        return {
          title: 'Child categories count',
          dataIndex: 'childCategoriesUUIDs',
          render: (text: string[]) => text.length,
          sorter: (a: DataType, b: DataType): number =>
            a.childCategoriesUUIDs.length - b.childCategoriesUUIDs.length,
          width: '10%',
        };
      }

      return {
        title: 'Parent category',
        dataIndex: 'parentCategoryUUID',
        render: (text: string): React.ReactElement | string => {
          if (!text) {
            return 'PARENT';
          }

          const parentCategory: IAdminCategory | undefined =
            Categories.categories?.find(
              (category: IAdminCategory): boolean => category.uuid === text,
            );

          if (!parentCategory) {
            return '---';
          }

          const nameLocalized: string | null = getTranslation(
            parentCategory.names,
            Language.languageType,
          );

          return (
            <Link
              to={`/category/${parentCategory.uuid}`}
              label={nameLocalized || '---'}
              openInNewTab
            />
          );
        },
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.parentCategoryUUID, b.parentCategoryUUID),
        width: '10%',
      };
    };

    const columnUE: ColumnsType<object> = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          stringCompare(a.name, b.name),
        render: (text: string, record: DataType): React.ReactElement => (
          <Link to={`/category/${record.uuid}`} label={text} openInNewTab />
        ),
        width: '50%',
        ...getColumnSearch('name'),
      },
      {
        title: 'Position',
        dataIndex: 'position',
        render: (text: number): number => text,
        sorter: (a: DataType, b: DataType): number => a.position - b.position,
        width: '10%',
      },
      showParentOrChildCategoriesColumn(),
      {
        title: 'Comparison portal',
        dataIndex: 'comparisonPortalTypes',
        render: (
          comparisonPortalTypes: IComparisonPortal[],
        ): React.ReactElement[] => {
          return comparisonPortalTypes.map(
            (comparisonPortalType: IComparisonPortal): React.ReactElement => (
              <Tag key={comparisonPortalType.uuid}>
                {comparisonPortalType.name}
              </Tag>
            ),
          );
        },
        sorter: (a: DataType, b: DataType): number =>
          a.comparisonPortalTypes.length - b.comparisonPortalTypes.length,
        width: '10%',
      },
      {
        title: 'Ebay',
        dataIndex: 'ebay',
        render: ({
          marketplaces,
        }: {
          marketplaces: IEbayMarketplace[];
        }): React.ReactElement[] =>
          marketplaces.map(
            ({ name, code }: IEbayMarketplace): React.ReactElement => (
              <Tag key={code}>{name}</Tag>
            ),
          ),
        sorter: (a: DataType, b: DataType): number =>
          a.ebay.marketplaces.length - b.ebay.marketplaces.length,
        width: '10%',
      },
      {
        title: 'Actions',
        render: (_text, record: DataType): React.ReactElement => (
          <a
            href={`${Config.url}/${getLowerCaseLanguageCode(Language.languageType)}/category/${
              record.uuid
            }`}
            target="_blank"
            rel="noreferrer noopener nofollow"
            aria-label="Open category in new tab"
          >
            <GlobalOutlined />
          </a>
        ),
        width: '10%',
      },
    ];

    setColumns(columnUE);

    const dataSourceSortableListUE = dataSourceUE.map(
      ({ key, name, childCategoriesUUIDs, position }: DataType) => ({
        id: key,
        uuid: key,
        name: `${name} (${childCategoriesUUIDs.length})`,
        position,
      }),
    );

    setDataSource(dataSourceUE);
    setDataSourceSortableList(dataSourceSortableListUE);
  }, [
    Categories,
    Language.languageType,
    Config.url,
    Language.languageType?.code,
    showOnlyParentCategories,
  ]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Button
          type={!Categories.categories ? 'primary' : 'default'}
          onClick={(): void => {
            dispatch(categoriesLoadAll());
          }}
          disabled={Categories.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        <Space>
          <Typography>Show only parent categories</Typography>

          <Switch
            checked={showOnlyParentCategories}
            onChange={(checked: boolean): void =>
              setShowOnlyParentCategories(checked)
            }
          />
        </Space>

        <Typography>
          {Categories.updatedAt &&
            `Updated at ${dayjs(Categories.updatedAt).fromNow()}`}
        </Typography>
      </Space>

      <LanguageButtons />

      <Form form={form} onFinish={onFinish}>
        <Row gutter={[0, 24]}>
          <Col span={8} offset={8} className="gutter-row">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  min: 3,
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Create new category name"
                prefix={<EditOutlined />}
                type="string"
                disabled={Categories.inProcess}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Button
        type="primary"
        onClick={(): void => setIsModalOpen(true)}
        disabled={!showOnlyParentCategories}
      >
        Change categories order
      </Button>

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Categories.inProcess && !Categories.categories}
      />

      <Modal
        title="Change categories order"
        open={isModalOpen}
        onOk={(): void => setIsModalOpen(false)}
        onCancel={(): void => setIsModalOpen(false)}
      >
        <SortableList
          records={dataSourceSortableList}
          id="categories-sortable-list"
          ItemTemplate={ItemTemplate}
          saveCallback={(records: ISortableListItem[]): void => {
            records.forEach((record: ISortableListItem): void => {
              dispatch(categorySavePosition(record.uuid, record.position));
            });
          }}
        />
      </Modal>
    </Space>
  );
}

const mapStateToProps = ({ Categories, Language, Config }: RootState) => ({
  Categories,
  Language,
  Config,
});

export default connect(mapStateToProps)(CategoriesContainer);
