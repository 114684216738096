import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { stringCompare } from '@mahawi/eshop-common/dist/src/string-compare';
import {
  type ICurrencyType,
  type IEbayMarketplace,
  type IProductEbayDetail,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { type ICurrencyState } from 'admin/react/reducers/currency/types';
import { type IProductState } from 'admin/react/reducers/product/types';
import { Button, Col, Divider, Form, Input, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { ebayProductSave } from '../../../reducers/ebay/actions';
import {
  type IEbayState,
  type ProductSave,
} from '../../../reducers/ebay/types';
import Link from '../../link';

interface ISelectOption {
  value: string;
  label: string;
}

interface ISelectOption {
  value: string;
  label: string;
}

const initialValues = {
  inStock: 2,
  priceIncreaseRate: 15,
  paymentPolicyId: '',
  fulfillmentPolicyId: '',
  returnPolicyId: '',
};

const onFinishMarketplace = (
  { name },
  {
    paymentPolicyId,
    fulfillmentPolicyId,
    returnPolicyId,
    inStock,
    priceIncreaseRate,
  },
  productUUID: string,
  dispatch: Dispatch<ProductSave>,
): void => {
  dispatch(
    ebayProductSave(
      productUUID,
      name,
      paymentPolicyId,
      fulfillmentPolicyId,
      returnPolicyId,
      inStock,
      priceIncreaseRate,
    ),
  );
};

function EBayMarketplaceContainer({
  Currency,
  dispatch,
  Ebay,
  Marketplace,
  Product,
}: {
  Currency: ICurrencyState;
  dispatch: Dispatch;
  Ebay: IEbayState;
  Marketplace: IEbayMarketplace;
  Product: IProductState;
}): React.ReactElement {
  const [form] = Form.useForm();
  const priceIncreaseRateValue = Form.useWatch('priceIncreaseRate', form);

  const [paymentSelectOptions, setPaymentSelectOptions] = useState<
    ISelectOption[]
  >([]);
  const [fulfillmentSelectOptions, setFulfillmentSelectOptions] = useState<
    ISelectOption[]
  >([]);
  const [returnSelectOptions, setReturnSelectOptions] = useState<
    ISelectOption[]
  >([]);
  const [productEbay, setProductEbay] = useState<
    IProductEbayDetail | undefined | null
  >(undefined);
  const [productPriceEUR, setProductPriceEUR] = useState<number | undefined>(
    undefined,
  );
  const [productPriceUSD, setProductPriceUSD] = useState<number | undefined>(
    undefined,
  );

  const [currencyTypeEUR, setCurrencyTypeEUR] = useState<
    ICurrencyType | undefined
  >(undefined);
  const [currencyTypeUSD, setCurrencyTypeUSD] = useState<
    ICurrencyType | undefined
  >(undefined);

  useEffect(() => {
    const paymentSelectOptionsUE = Ebay.paymentPolicies[Marketplace.code]
      ?.sort((a, b) => stringCompare(a.name, b.name))
      .map(({ name, paymentPolicyId }) => {
        const pso: ISelectOption = {
          value: paymentPolicyId,
          label: name,
        };

        return pso;
      });

    setPaymentSelectOptions(paymentSelectOptionsUE);
  }, [Ebay.paymentPolicies, Marketplace.code]);

  useEffect(() => {
    const fulfillmentSelectOptionsUE = Ebay.fulfillmentPolicies[
      Marketplace.code
    ]
      ?.sort((a, b) => stringCompare(a.name, b.name))
      .map(({ name, fulfillmentPolicyId }) => {
        const fso: ISelectOption = {
          value: fulfillmentPolicyId,
          label: name,
        };

        return fso;
      });

    setFulfillmentSelectOptions(fulfillmentSelectOptionsUE);
  }, [Ebay.fulfillmentPolicies, Marketplace.code]);

  useEffect(() => {
    const returnSelectOptionsUE = Ebay.returnPolicies[Marketplace.code]
      ?.sort((a, b) => stringCompare(a.name, b.name))
      .map(({ name, returnPolicyId }) => {
        const rso: ISelectOption = {
          value: returnPolicyId,
          label: name,
        };

        return rso;
      });

    setReturnSelectOptions(returnSelectOptionsUE);
  }, [Ebay.returnPolicies, Marketplace.code]);

  useEffect(() => {
    const productEbayUE = Ebay.productEbay[Marketplace.code];

    setProductEbay(productEbayUE);
  }, [Ebay.productEbay, Marketplace.code]);

  useEffect(() => {
    if (productEbay) {
      form.setFieldsValue({
        inStock: productEbay.inStock,
        priceIncreaseRate: productEbay.priceIncreaseRate,
        paymentPolicyId: productEbay.paymentPolicy.id,
        fulfillmentPolicyId: productEbay.fulfillmentPolicy.id,
        returnPolicyId: productEbay.returnPolicy.id,
      });
    }
  }, [productEbay, form]);

  useEffect(() => {
    const productPriceEURUE = Product.prices.find(
      ({ currencyType }) => currencyType.isoCode === 'EUR',
    )?.price;

    const productPriceUSDUE = Product.prices.find(
      ({ currencyType }) => currencyType.isoCode === 'USD',
    )?.price;

    setProductPriceEUR(productPriceEURUE);
    setProductPriceUSD(productPriceUSDUE);
  }, [Product.prices, Product.uuid]);

  useEffect(() => {
    const currencyTypeEURUE = Currency.currencies.find(
      ({ isoCode }) => isoCode === 'EUR',
    );
    const currencyTypeUSDUE = Currency.currencies.find(
      ({ isoCode }) => isoCode === 'USD',
    );

    setCurrencyTypeEUR(currencyTypeEURUE);
    setCurrencyTypeUSD(currencyTypeUSDUE);
  }, [Currency.currencies]);

  return (
    <Form
      onFinish={(values) =>
        onFinishMarketplace(Marketplace, values, Product.uuid, dispatch)
      }
      disabled={Ebay.inProcess}
      initialValues={initialValues}
      form={form}
    >
      <Divider orientation="left">
        <Space size={16} align="start">
          {Marketplace.name}
          <Link to={`/ebay/marketplace/${Marketplace.code}`} openInNewTab />
        </Space>
      </Divider>
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item
            name="paymentPolicyId"
            rules={[
              {
                required: true,
              },
            ]}
            label="Payment policy"
          >
            <Select
              placeholder="Payment policy"
              options={paymentSelectOptions}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="fulfillmentPolicyId"
            rules={[
              {
                required: true,
              },
            ]}
            label="Fulfillment policy"
          >
            <Select
              placeholder="Fulfillment policy"
              options={fulfillmentSelectOptions}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="returnPolicyId"
            rules={[
              {
                required: true,
              },
            ]}
            label="Return policy"
          >
            <Select placeholder="Return policy" options={returnSelectOptions} />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="inStock"
            rules={[
              {
                required: true,
                type: 'number',
                min: 0,
                message: 'In stock must be a positive number.',
                transform: (value) => Number(value),
              },
            ]}
          >
            <Input type="number" addonBefore="In stock" addonAfter="pcs" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="priceIncreaseRate"
            rules={[
              {
                required: true,
                type: 'number',
                min: 0,
                message: 'Price increase rate must be a positive number.',
                transform: (value) => Number(value),
              },
            ]}
          >
            <Input
              type="number"
              addonBefore="Price increase rate"
              addonAfter="%"
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>

        <Col span={4}>
          <Space direction="vertical" size={16} align="start">
            <strong>
              Ebay price in EUR will be{' '}
              {productPriceEUR && currencyTypeEUR
                ? formatCurrency(
                    productPriceEUR * (1 + priceIncreaseRateValue / 100),
                    currencyTypeEUR,
                  )
                : '---'}
              .
            </strong>
            <strong>
              Ebay price in USD will be{' '}
              {productPriceUSD && currencyTypeUSD
                ? formatCurrency(
                    productPriceUSD * (1 + priceIncreaseRateValue / 100),
                    currencyTypeUSD,
                  )
                : '---'}
              .
            </strong>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = ({ Ebay, Product, Currency }: RootState) => ({
  Ebay,
  Product,
  Currency,
});

export default connect(mapStateToProps)(EBayMarketplaceContainer);
